import React from "react"
import useMinimalConfig from "../../hooks/use-minimal-config"
import Navigation from "./components/navigation"
import HeaderTitle from "./components/header-title"
import { HeaderType } from "../../types"

type LayoutProps = {
  header: HeaderType
  [key: string]: any
}

const Header = ({ header, ...props }: LayoutProps) => {
  const { navigation: nav } = useMinimalConfig()

  return (
    <React.Fragment>
      <div className={`h-12 lg:h-16 w-full ${header.advanced?.class || ``}`} />
      <div className="fixed top-0 w-full z-50 bg-header shadow-md">
        <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0">
          <div className="flex justify-center md:justify-between items-center border-b-2 border-gray-100 py-8 md:justify-start md:space-x-10">
            <div className="flex md:justify-start lg:w-0 lg:flex-1">
              <HeaderTitle logo={header?.logo} logoLink={header?.logoLink} desktopHeight={header?.logoHeightLg || 30} mobileHeight={header?.logoHeightSm || 30} />
            </div>
            {
              nav && nav.length > 0 && <div className="-mr-2 -my-2 w-1/2 md:w-auto">
                <Navigation nav={nav} />
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
